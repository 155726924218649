import React from "react";
import "../styles/components/publications.css";


const articles = [
  {
    id: 1,
    title:
      "Forecasting Short-Term Indoor Radon: A Machine Learning Approach Using LSTM Networks - IEEE Publication",
    date: "August 15th, 2023",
    duration: "12 minutes read",
    content:
      "Explore the realm of indoor radon forecasting in Implementing LSTM-Based Approaches for Indoor Radon Forecasting. Delving into <strong>Machine Learning</strong> (ML) techniques, this work evaluates two LSTM-based methods for predicting future indoor radon levels, crucial for managing exposure risk. The study unveils promising preliminary results, highlighting the effectiveness of the <strong>Long Short-Term Memory</strong> (LSTM) algorithm in providing accurate forecasts across different time windows. These findings offer insights into using ML as a valuable tool for Indoor Air Quality management, aiding in preventive measures to address health risks associated with indoor radon exposure.",
    url: "https://ieeexplore.ieee.org/document/10211807",
  },
  {
    id: 2,
    title: "Moz Memes: A Platform for Sharing Mozambican Memes",
    date: "August 8th, 2024",
    duration: "7 minutes read",
    content: `Discover the development journey behind <strong>Moz Memes</strong>, a web application designed to search, upload, and share Mozambican memes. Built using <strong>React.js</strong> for the frontend, <strong>Go (Golang)</strong> for the backend, and <strong>Node.js</strong> for NSFW content validation, Moz Memes is tailored for efficient resource usage and accessibility across devices. This article explores the challenges of building the platform, including content moderation and optimizing performance for users with limited internet access.`,
    url: "https://medium.com/@valdompinga57/why-and-how-i-made-a-meme-database-with-an-emphasis-on-mozambican-memes-8c04308c8d34",
  },
  {
    id: 3,
    title:
      "From Pen to Pixel: My Journey Creating a Virtual Notebook Shelf - LinkedIn Publication",
    date: "September 16th, 2023",
    duration: "8 minutes read",
    content: `Embark on my journey from traditional notebooks to a digital solution in this article. Faced with a surplus of unused paper, I blend the concept of the <strong>Infinite Book</strong> with technology, resulting in the creation of the <strong>Notebook Shelf</strong> app. This innovative app lets users digitize notebooks, creating virtual shelves with seamless Dropbox integration for data storage.`,
    url: "https://www.linkedin.com/pulse/from-pen-pixel-my-journey-creating-virtual-valdo-mpinga/",
  },
];

const Publications = () => {
  return (
    <div className="recent-articles">
      <h2 className="recentArticlesTitle">Publications</h2>
      <hr className="separator" />
      {articles.map((article) => (
        <div key={article.id} className="article">
          <div className="title">{article.title}</div>
          <div className="date-duration">
            {article.date} | {article.duration}
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: article.content }}
          ></div>
          <div className="read-more">
            <a href={article.url} target="_blank" rel="noopener noreferrer">
              Read article...
            </a>
          </div>
          <hr className="separator" />
        </div>
      ))}
    </div>
  );
};

export default Publications;
